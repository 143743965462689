/* src/App.css */

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-both-container {
  display: flex;
  flex-direction: row;
  padding: 20px;
  background-color: #ffffff;
  height: 900px;
}

.AlphabetNavigation {
  width: 60px; /* 固定宽度，确保内容不被遮挡 */
  flex-shrink: 0;
}

.Home {
  flex: 1;
  overflow-y: auto;
  padding-left: 20px; /* 确保内容不被字母表导航遮挡 */
  background-color: #f8f9fa;
  border-left: 1px solid #ddd;
}

.Footer {
  background-color: #f1f1f1;
  padding: 10px;
  text-align: center;
  border-top: 1px solid #ddd;
}

/* Media Queries for Mobile Devices */
@media (max-width: 768px) {
  .content-both-container {
    display: flex;
    flex-direction: row;
    padding: 20px;
    background-color: #ffffff;
    height: auto;
  }
}