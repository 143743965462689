/* src/components/AlphabetNavigation/AlphabetNavigation.css */

.alphabet-navigation {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    width: 60px; /* 适当宽度 */
    height: 100%; /* 相对于父容器的全高度 */
    overflow-y: auto;
}

.alphabet-button {
    background: #d3d3d3;
    border: 2.0px solid #000000;
    padding: 6px 0;
    margin: 6.8px 0;
    cursor: pointer;
    font-size: 16px;
    color: #394048;
    transition: color 0.3s, border-color 0.3s;
    border-radius: 50%;
    width: 21px;
    height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.alphabet-button:hover {
    color: #0056b3;
    border-color: #0056b3; /* 悬停时改变边框颜色 */
    background: #5ebfb0;
}

.alphabet-button:focus {
    outline: none;
    color: #0056b3;
    border-color: #0056b3; /* 聚焦时改变边框颜色 */
}

/* Media Queries for Mobile Devices */
@media (max-width: 768px) {
    .alphabet-navigation {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        width: 60px; /* 适当宽度 */
        height: 90%; /* 相对于父容器的全高度 */
        overflow-y: auto;
    }
}