.add-slang-container {
    width: 95%;
    margin: 0px auto;
    padding: 20px;
}

.add-slang-container h2 {
    text-align: center;
    width: 80%;
    margin-bottom: 20px;
    margin-top: 0;
    font-family: "Your Desired Font", sans-serif;
    color: #333;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    font-size: 2em;
}

.slang-form {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
}

.left-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30%;
    padding-right: 10px;
    /* 添加右侧间距 */
}

.right-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 65%;
    padding-left: 10px;
    /* 添加左侧间距 */
}

.splitter {
    width: 0.5px;
    background-color: #ccc;
    cursor: col-resize;
    top: 0;
    bottom: 0;
    left: 30%;
    z-index: 1;
    border-left: 2px dashed #000;
    /* 添加虚线样式 */
    border-right: 2px dashed #000;
    /* 添加虚线样式 */
}

.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    font-family: 'Pacifico', cursive;
    font-size: 24px;
    color: #333;
}

.image-preview {
    width: 100%;
    height: 400px;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.image-preview img {
    max-width: 100%;
    max-height: 100%;
}

.button-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.generate-button {
    flex: 1;
    padding: 10px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
}

.generate-button.disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.generate-button.success {
    background-color: gray;
    color: white;
}



.custom-file-upload {
    display: flex;
    align-items: center;
}

.custom-file-upload button {
    padding: 10px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
}

.custom-file-upload .file-name {
    font-size: 14px;
    color: #666;
}

.form-group {
    margin-bottom: 15px;
}

.label {
    font-weight: bold;
}

.line-input {
    border: none;
    border-bottom: 2px solid #ccc;
    margin-top: -10px;
}

.slang-input {
    width: 95%;
    padding: 8px;
    border: none;
    border-bottom: 2px solid #000000;
    margin-top: 10px;
    outline: none;
}

.explanation-input {
    width: 95%;
    height: 200px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 10px;
    outline: none;
}

.audio-controls {
    display: flex;
    align-items: center;
}

.submit-button {
    padding: 10px 20px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    align-self: flex-start;
}

.submit-button.disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.submit-button.success {
    background-color: gray;
    color: white;
}

@media (max-width: 768px) {
    .left-section {
        width: 90% !important;
        padding-right: 10px;
        /* 添加右侧间距 */
    }

    .slang-form {
        display: flex;
        justify-content: space-between;
        width: 100%;
        position: relative;
        flex-direction: column;
    }

    .splitter {
        display: none;
    }
    
    .add-slang-container h2{
        display: none;
    }
}