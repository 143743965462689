.content-container {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    width: 100%;
    border: 1px solid #ccc;
    max-height: 950px;
    border-radius: 8px;
    }

.content-header {
    display: flex;
    justify-content: space-around;
    font-weight: bold;
    padding: 10px 30px 10px 0;
    background-color: #f0f0f0;
}

.content-body {
    display: flex;
    flex-direction: column;
    height: calc(100% - 40px);
    overflow-y: auto;
}

.content-item {
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
}

/* 通用样式 */
.content-header span {
    flex: 1;
    text-align: center;
}

.content-item > div {
    flex: 18;
    text-align: center;
}

/* .content-header 样式 */
.content-header span:nth-child(2) {
    flex: 4;
}

.content-header span:nth-child(3) {
    flex: 3;
}

.content-header span:nth-child(4) {
    flex: 5;
}

.content-header span:nth-child(5) {
    flex: 2;
}

.content-header span:nth-child(6) {
    flex: 2;
}

.content-header span:nth-child(7) {
    flex: 1;
}

.content-header span:nth-child(8) {
    flex: 1;
}

/* .content-item 样式 */
.content-item > div:nth-child(2) {
    flex: 1;
    display: flex;
    align-items: center;
}

.content-item > div:nth-child(3) {
    flex: 3;
}

.content-item > div:nth-child(4) {
    flex: 5;
}

.content-item > div:nth-child(5) {
    flex: 3;
}

.content-item > div:nth-child(6) {
    flex: 3;
}

.content-item > div:nth-child(7) {
    flex: 2;
}

.content-item > div:nth-child(8) {
    flex: 1;
}

.content-image img {
    height: 50px;
    width: 50px;
    object-fit: cover;
    border-radius: 50%;
}

.content-audio audio {
    width: 100%;
}

.delete-button {
    background-color: red;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

.confirm-delete {
    background-color: grey;
    color: black;
}

/* 搜索栏样式 */
.search-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
}

.search-bar input {
    width: 20%;
    padding: 10px;
    font-size: 16px;
    margin-left: 10px;
}

.title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    width: 50%;
}

/* Media Queries for Mobile Devices */
@media (max-width: 768px) {
    .content-container {
        margin: 10px;
    }

    .content-header {
        display: none;
    }

    .content-header span {
        width: 100%;
        text-align: left;
        padding: 5px 0;
    }

    .content-body {
        height: auto;
    }

    .content-item {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
    }

    .content-item > div {
        width: 100%;
        text-align: left;
        padding: 5px 0;
    }

    .content-image img {
        height: auto;
        width: 100% !important;
        max-width: 150px;
        margin-bottom: 10px;
    }

    .content-audio audio {
        width: 100%;
    }

    .search-bar {
        flex-direction: column;
        align-items: flex-start;
    }

    .search-bar input {
        width: 80%;
        margin-bottom: 10px;
    }

    .title {
        display: none;
    }

    .content-container {
        border: none;

        }
}