.content-container {
    width: 96%;
    height: 900px;
    margin: 10px auto;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.content-header {
    display: flex;
    justify-content: flex-start;
    background-color: #f8f9fa;
    padding: 10px 20px 10px 0;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
}

.content-body {
    height: calc(100% - 40px); /* 减去 header 的高度 */
    overflow-y: auto;
}

.content-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
}

.content-header span, .content-item > div {
    flex: 1;
    text-align: center;
}

.content-header span:nth-child(2), .content-item > div:nth-child(2) {
    flex: 4;
}

.content-header span:nth-child(3), .content-item > div:nth-child(3) {
    flex: 3;
}

.content-header span:nth-child(4), .content-item > div:nth-child(4) {
    flex: 5;
}

.content-header span:nth-child(5), .content-item > div:nth-child(5) {
    flex: 2;
}

.content-header span:nth-child(6), .content-item > div:nth-child(6) {
    flex: 2;
}

.content-header span:nth-child(7), .content-item > div:nth-child(7) {
    flex: 1;
}

.content-item img {
    height: 50px;
    width: 50px;
    object-fit: cover;
    border-radius: 50%;
}

.content-item button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
}

/* Media Queries for Mobile Devices */
@media (max-width: 768px) {
    .content-container {
        width: 100%;
        height: auto;
    }

    .content-header {
        display: none; /* 隐藏标题行 */
    }

    .content-body {
        height: auto;
    }

    .content-item {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
    }

    .content-item > div {
        width: 100%;
        text-align: left;
        padding: 5px 0;
    }

    .content-item img {
        height: auto;
        width: 100%;
        max-width: 150px;
        margin-bottom: 10px;
    }
}