.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    width: 500px;
}

.modal-content input {
    margin: 10px 0;
    padding: 8px;
    width: 100%;
    box-sizing: border-box;
}

.modal-content button {
    margin-top: 10px;
    padding: 10px 20px;
    cursor: pointer;
    margin-right: 10px;
}

/* Media Queries for Mobile Devices */
@media (max-width: 768px) {
    .modal-content {
        width: 90%;
        padding: 15px;
    }

    .modal-content input {
        padding: 6px;
    }

    .modal-content button {
        padding: 8px 16px;
    }
}